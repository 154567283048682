<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="100"
      class="pl-0"
    >
    <v-img
      :src="require('@/assets/LHG.png')"
      max-width="100"
      @click="fnGoToHome"
    />

    <v-spacer />

    <div>
      <v-tabs
        class="hidden-sm-and-down"
        optional
        background-color="transparent"
      >
        <v-tab
          v-for="(name, i) in items"
          :key="i"
          :to="{ name }"
          :exact="name === 'Home'"
          :ripple="false"
          class="font-weight-bold"
          min-width="96"
          text
        >
          {{ name }}
        </v-tab>
        <v-menu
          open-on-hover
          top
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar>
                <v-img
                  max-height="auto"
                  :src="fnGetLanguage.src"
                />
              </v-avatar>
              &nbsp;{{fnGetLanguage.title}}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(language, index) in languages"
              :key="index"
              @click="changeLocale(language)"
            >
              <v-list-item-avatar>
                <v-img
                  :src="language.src"
                />
              </v-list-item-avatar>
              <v-list-item-title>
                  {{ language.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tabs>
    </div>
    <v-app-bar-nav-icon
    class="hidden-md-and-up"
    @click="drawer = !drawer"
    />
  </v-app-bar>

  <home-drawer
  v-model="drawer"
  :items="items"
  />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },
    computed: {
      fnGetLanguage () {
        if (this.$i18n.locale === 'en') {
          return this.languages[1]
        } else {
          return this.languages[0]
        }
      },
    },
    data: () => ({
      locale: '',
      drawer: null,
      items: [
        'Home',
        'About',
        'Touchfree',
        'Products',
        'Contact',
      ],
      languages: [
        {
          title: '한국어',
          locale: 'ko',
          src: require('@/assets/flag_ko.png'),
        },
        {
          title: 'English',
          locale: 'en',
          src: require('@/assets/flag_us.png'),
        },
      ],
    }),
    methods: {
      fnGoToHome () {
        this.$router.push('/')
      },
      changeLocale (language) {
        if (language.locale === 'en') {
          this.$i18n.locale = 'en'
        } else {
          this.$i18n.locale = 'ko'
        }
        this.$EventBus.$emit('localeChanged')
        console.log(this.$i18n.locale)
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
